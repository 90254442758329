import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Intersect from 'vuetify/lib/directives/intersect';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { align: "left", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _vm.mostrarBotaoFlutuante && _vm.selecionados.length !== 0
            ? _c(
                VTooltip,
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                {
                                  attrs: {
                                    fab: "",
                                    dark: "",
                                    color: "green",
                                    fixed: "",
                                    right: "",
                                    bottom: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.modalEmail = true
                                    }
                                  }
                                },
                                on
                              ),
                              [
                                _c(VIcon, { attrs: { dark: "" } }, [
                                  _vm._v("download")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    959324881
                  )
                },
                [_c("span", [_vm._v("Baixar instrumentos")])]
              )
            : _vm._e(),
          _c(
            VCard,
            { attrs: { align: "left", justify: "left" } },
            [
              _c(
                VCardText,
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    VIcon,
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" mdi-sync ")]
                  ),
                  _vm._v("Títulos > Baixar Instrumentos de Protesto em Massa ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "mt-2 mb-3",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.botaoFiltrar.apply(null, arguments)
                }
              }
            },
            [
              _c(
                VRow,
                { staticClass: "mx-6 mt-3 pt-3", attrs: { align: "center" } },
                [
                  _c(
                    VCol,
                    {
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "8"
                      }
                    },
                    [
                      _c(
                        VCardText,
                        {
                          staticClass:
                            "text-left font-weight-black display-1 pl-0",
                          style: { color: _vm.primaryColorFont }
                        },
                        [
                          _c(
                            VIcon,
                            { staticClass: "ma-2", attrs: { large: "" } },
                            [_vm._v(" mdi-download-circle-outline ")]
                          ),
                          _vm._v("Baixar Instrumentos de Protesto em lote ")
                        ],
                        1
                      ),
                      _c(VTextarea, {
                        attrs: {
                          solo: "",
                          small: "",
                          label:
                            "Cole aqui os títulos que deseja solicitar o download de Instrumento de Protesto",
                          hint:
                            "Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5",
                          placeholder: "Exemplo:  477977/1, 721603/6, 755202/5",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.ds_nosso_numero,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                          },
                          expression: "filtros.ds_nosso_numero"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "mt-2",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "4" }
                    },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } },
                        [
                          _c(
                            VCard,
                            { staticClass: "grey white--text white--text" },
                            [
                              _c(
                                VIcon,
                                { staticClass: "ma-2 text-left white--text" },
                                [_vm._v(" mdi-chevron-down ")]
                              ),
                              _vm._v("fazer upload de arquivo XLS ")
                            ],
                            1
                          ),
                          _c(
                            VCardText,
                            {
                              staticClass: "font-weight-light text-left title-1"
                            },
                            [
                              _vm._v(
                                " Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para downoad de Instrumentos de Protesto. Formatos compatíveis: .xls/.xlsx/.xlsm Limite de tamanho: 60mb "
                              )
                            ]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "grey white--text",
                              attrs: {
                                block: "",
                                plain: "",
                                small: "",
                                href: _vm.link_modelo
                              }
                            },
                            [
                              _c(VIcon, { staticClass: "ma-2 text-left" }, [
                                _vm._v(" mdi-file-download ")
                              ]),
                              _vm._v("Baixar modelo ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "mt-2",
                          attrs: { cols: "12", sm: "12" }
                        },
                        [
                          _c(VFileInput, {
                            ref: "myFileInput",
                            staticClass: "mb-n7 white--text",
                            attrs: {
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                              label: "Selecione o arquivo (.xls/.xlsx/.xlsm)",
                              "show-size": "",
                              chips: "",
                              solo: "",
                              id: "fileInput",
                              name: "file"
                            },
                            on: { change: _vm.uploadFileReference }
                          }),
                          _vm.fazendoUpload
                            ? _c(
                                VCardText,
                                {
                                  staticClass:
                                    "font-weight-light text-left title-1"
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "ma-1",
                                      attrs: {
                                        color: "green",
                                        "x-small": "",
                                        elevation: "0",
                                        fab: ""
                                      }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("mdi-check")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    " Títulos verificados: " +
                                      _vm._s(_vm.retornaDadosXls()) +
                                      " linhas"
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.fazendoUpload
                            ? _c(
                                VCardText,
                                { staticClass: "text-left title-1" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "ma-1",
                                      attrs: {
                                        color: "green",
                                        "x-small": "",
                                        elevation: "0",
                                        fab: ""
                                      }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("mdi-magnify")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" Para confirmar, clique em Filtrar ")
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    [
                      _c(
                        VExpansionPanels,
                        [
                          _c(
                            VExpansionPanel,
                            [
                              _c(
                                VExpansionPanelHeader,
                                {
                                  staticClass: "grey lighten-4",
                                  attrs: { small: "" }
                                },
                                [
                                  _c(
                                    VCardText,
                                    {
                                      staticClass:
                                        "font-weight-light black--text"
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "primary--text lighten-2"
                                        },
                                        [_vm._v(" search ")]
                                      ),
                                      _vm._v(" Filtrar Resultados ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VExpansionPanelContent,
                                [
                                  _c(
                                    VRow,
                                    { staticClass: "my-3" },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "4",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              label: "CPF/CNPJ",
                                              hint:
                                                "Informe o Número do CPF/CNPJ",
                                              placeholder: "Ex.:000.000.000-00",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.filtros
                                                  .nu_identificacao_devedor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "nu_identificacao_devedor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                                                filtros.nu_identificacao_devedor\n                                            "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "5",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              label: "Nome",
                                              hint:
                                                "Informe o nome para efetuar a busca",
                                              placeholder: "Ex.: José",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.filtros.nm_devedor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "nm_devedor",
                                                  $$v
                                                )
                                              },
                                              expression: "filtros.nm_devedor"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "10",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c(VSelect, {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              items: _vm.listaUfs,
                                              label: "UF",
                                              "return-object": true
                                            },
                                            model: {
                                              value: _vm.filtros.cd_uf_devedor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "cd_uf_devedor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filtros.cd_uf_devedor"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VRow,
                { staticClass: "mx-0 mt-2 mb-0 pa-0" },
                [
                  _c(VSpacer),
                  _c(
                    VCol,
                    {
                      staticClass: "pr-9",
                      attrs: {
                        cols: "10",
                        xs: "6",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        align: "center"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "d-flex justify-center",
                          attrs: {
                            block: "",
                            color: "primary",
                            loading: _vm.loadingFiltros
                          },
                          on: { click: _vm.botaoFiltrar }
                        },
                        [
                          _vm._v(" Filtrar "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.modalEmail
            ? _c(
                VDialog,
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.modalEmail,
                    callback: function($$v) {
                      _vm.modalEmail = $$v
                    },
                    expression: "modalEmail"
                  }
                },
                [
                  _c(
                    VCard,
                    [
                      _c(
                        VCardText,
                        [
                          _c(
                            VCardTitle,
                            { staticClass: "headline primary--text" },
                            [_vm._v("Instrumentos de Protesto via e-mail")]
                          ),
                          _c(VSpacer),
                          _c(
                            VForm,
                            {
                              ref: "formEmail",
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.checarEmailValido()
                                }
                              },
                              model: {
                                value: _vm.emailValido,
                                callback: function($$v) {
                                  _vm.emailValido = $$v
                                },
                                expression: "emailValido"
                              }
                            },
                            [
                              _c(VTextField, {
                                attrs: {
                                  "prepend-icon": "person",
                                  name: "email",
                                  label: "E-mail",
                                  type: "email",
                                  rules: _vm.emailRegras,
                                  required: "",
                                  "data-cy": "signinEmailField"
                                },
                                model: {
                                  value: _vm.emailsInstrumento,
                                  callback: function($$v) {
                                    _vm.emailsInstrumento = $$v
                                  },
                                  expression: "emailsInstrumento"
                                }
                              })
                            ],
                            1
                          ),
                          _c(VCardSubtitle, [
                            _vm._v(
                              "Digite o e-mail onde deseja receber os Instrumentos de Protesto. Caso deseje receber em mais de um e-mail, favor, preenchê-los separando por vírgula."
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _c(
                            VBtn,
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.emailValido,
                                "data-cy": "enviarEmailsInstrumentos"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.baixarInstrumentos()
                                }
                              }
                            },
                            [
                              _vm._v(" Enviar "),
                              _c(VIcon, { staticClass: "ml-1" }, [
                                _vm._v("chevron_right")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemRetornoSnackbar
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.titulos,
              "item-text": "titulo",
              options: _vm.options,
              loading: _vm.loading,
              "single-select": false,
              "item-key": "id_titulo",
              "show-select": "",
              "footer-props": {
                itemsPerPageOptions: [50, 1000, 5000, -1],
                itemsPerPageText: "Itens por página:"
              },
              "server-items-length": _vm.totalTitulos,
              "loading-text": "Pesquisando títulos...",
              "no-data-text": "Preencha os filtros e clique em filtrar...",
              "no-results-text": "Nenhum título encontrado..."
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.ds_nosso_numero",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.ds_nosso_numero))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.ds_nosso_numero))
                          ])
                    ]
                  }
                },
                {
                  key: "item.nu_identificacao_devedor",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.formatDocumento(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.formatDocumento(item)))
                          ])
                    ]
                  }
                },
                {
                  key: "item.nm_devedor",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.nm_devedor))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.nm_devedor))
                          ])
                    ]
                  }
                },
                {
                  key: "item.cd_status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.getDescSituacao(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.getDescSituacao(item)))
                          ])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selecionados,
              callback: function($$v) {
                _vm.selecionados = $$v
              },
              expression: "selecionados"
            }
          }),
          _c(
            VCard,
            { staticClass: "mt-3" },
            [
              _c(
                VRow,
                { staticClass: "ma-0" },
                [
                  _c(VSpacer),
                  _c(
                    VCol,
                    {
                      staticClass: "d-flex justify-end pr-9",
                      attrs: { sm: "6", md: "3", lg: "4" }
                    },
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: {
                            top: "",
                            disabled: _vm.selecionados.length !== 0
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          directives: [
                                            {
                                              def: Intersect,
                                              name: "intersect",
                                              rawName: "v-intersect",
                                              value: _vm.onIntersect,
                                              expression: "onIntersect"
                                            }
                                          ],
                                          staticClass: "d-flex justify-center",
                                          attrs: {
                                            color: "green white--text",
                                            disabled: !_vm.selecionados.length
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.modalEmail = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(" Baixar Instrumentos "),
                                          _c(
                                            VIcon,
                                            { staticClass: "ml-2" },
                                            [_vm._v("mdi-chevron-right")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Selecione um ou mais títulos para liberar o comando"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }